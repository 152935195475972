<template>
    <div :class="backCovers.video == 1 ? 'grid-bg w-screen relative flex items-start' : 'grid-bg h-screen w-screen relative flex items-end'">
        <!-- begin::cover video-->
        <div v-if="backCovers.video == 1">
            <div class="video-wrapper">
                <vimeo-player :video-id="backCovers.videoLink" :options="playerOptions"></vimeo-player>
            </div>
            
            <div v-if="false" class="cover-logo hidden lg:block absolute top-24 left-0 mt-10 w-full">
                <inline-svg width="5rem" height="auto" style="filter:invert(1);margin: 0 auto;" src="/svg/logos/logo_agency_white.svg" />
            </div>


            <div class="cover-logo hidden lg:block absolute top-0 left-0 w-full" style="margin-top: -5%;">
                <img height="auto" width="50%" style="filter:invert(0);margin: 0 auto;" src="/new_logos/2022-LA-Agency-Logotipo_Gold.png" />
            </div>
        </div>
        <!-- begin::cover video-->
            <transition v-if="backCovers.video == 0" mode="in-out" name="fade">
                <img :key="cover.text" class="h-screen w-full object-cover absolute" v-lazy="isMobile ? cover.imgMobile : cover.img" alt="car">
            </transition>
            <!-- end::cover image-->
            <div v-if="backCovers.video == 0" class="z-30 text-xl md:text-hero font-blackMango text-white uppercase w-screen lg:w-10/12 leading-tight p-5 mb-2 md:p-14 md:pb-8 lg:p-24 lg:pb-16">
                
                <div v-if="false" class="cover-logo hidden lg:block absolute top-24 left-0 mt-10 w-full">
                <inline-svg width="5rem" height="auto" style="filter:invert(1);margin: 0 auto;" src="/svg/logos/logo_agency_white.svg" />
                </div>


                <div class="cover-logo hidden lg:block absolute top-0 left-0 w-full" style="margin-top: -5%;">
                    <img height="auto" width="50%" style="filter:invert(0);margin: 0 auto;" src="/new_logos/2022-LA-Agency-Logotipo_Gold.png" />
                </div>
                
                <!-- begin::cover text-->
                <transition mode="out-in" name="slide-fade">
                    <p :key="cover.text"> {{cover.text}}</p>
                </transition>
                <!-- end::cover text-->
                <!-- begin::navigation-->
                <div class="flex font-euclid text-md mt-4 justify-start items-center space-x-2 md:space-x-4 text-white">
                    <!-- begin::navigation numbers-->
                    <p class="w-2">{{currentCover+1}}</p>
                    <div class="relative">
                        <hr class="w-16 md:w-28 lg:w-44 opacity-40">
                        <hr id="timer" class=" absolute m-y-auto inset-y-0">
                    </div>
                    <p class="w-2">{{coversList.length}}</p> 
                    <!-- end::navigation numbers-->

                    <!-- begin::navication arrows-->
                    <button @click="toPreviousCover()">
                        <inline-svg fill="white" class="hover:scale-125 origin-center transition-all duration-300 transform rotate-180 w-3 h-3 md:w-4 md:h-4 lg:w-6 lg:h-6"
                            src="/svg/arrow/arrow.svg" />
                    </button>
                    <button @click="toNextCover()">
                        <inline-svg fill="white" class="hover:scale-125 origin-center transition-all duration-300 transform w-3 h-3 md:w-4 md:h-4 lg:w-6 lg:h-6" src="/svg/arrow/arrow.svg" />
                    </button>
                    <!-- end::navigation arrows -->
                </div>
                <!-- end::navigation-->
            </div>
            <!-- begin::overlay -->
            <div v-if="backCovers.video == 0" class="absolute overlay-cover h-full w-full">
            </div>
            <!-- end::overlay-->
    </div>
</template>

<style lang="scss" scoped>

.cover-logo{
  padding-bottom: 4rem;
  svg {
    width: clamp(30px, 15rem, 20vh );
  }
}
.h-screen{
    height: calc(var(--vh, 1vh) * 100);
}

.animate {
    animation: timer 8s linear;
}
@keyframes timer {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}
</style>
<script src="//unpkg.com/vue@2.4"></script>
<script src="//unpkg.com/vue-vimeo-player"></script>
<script>
export default {
    name: "Cover",

    data() {
        return {
            currentCover: 0,
            cover: null,
            interval: null,
            intervalTime: 8000,
            i: 0,
            coversList: null,
            isMobile: false,
            backCovers: {video: false},
            playerOptions: {
                autoplay: true,
                muted: true,
                controls: false,
                width: window.innerWidth,
                height: ((window.innerWidth*9)/16),
            },
        }
    },
    methods: {
        toNextCover() {
            if (this.currentCover === this.coversList.length - 1) {
                this.currentCover = 0;
                this.cover = this.coversList[this.currentCover];

            } else {
                this.currentCover++;
                this.cover = this.coversList[this.currentCover];
            }
            this.resetTimerAnimation();
            this.resetInterval()
        },
        toPreviousCover() {
            if (this.currentCover === 0) {
                this.currentCover = this.coversList.length - 1;
                this.cover = this.coversList[this.currentCover];

            } else {
                this.currentCover--;
                this.cover = this.coversList[this.currentCover];
            }
            this.resetTimerAnimation();
            this.resetInterval()
        },
        resetTimerAnimation() {
            document.getElementById('timer')?.classList?.remove?.("animate")
            setTimeout(() => {
                document.getElementById('timer')?.classList?.add?.("animate");
            }, 10)
        },
        resetInterval() {
            clearInterval(this.interval);
            this.interval = setInterval(this.toNextCover, this.intervalTime);
        },
    },
    setup(){
        let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    created() {
        if(screen.width < 768) this.isMobile = true;
        this.coversList = Object.keys(this.$i18n.t('homepage.cover')).map(key => {
            return this.$i18n.t('homepage.cover')[key];
        })
        this.cover = this.coversList[this.currentCover];

        // https://api.la-agency.com/media/i/frontend/1942edd71907d666

        // ISTO É PARA O RUI

        // O link em cima é o link que é usado para aceder as imagens na base de dados
        // LINK: https://api.la-agency.com/media/i/frontend/[IMAGEM]
        // Em [IMAGEM] é o nome da imagem que vem do backend. Ver código em baixo
        
        
        // Isto é para aceder as covers que estão carregadas no backend
        // Se fizeres console.log vais ter acesso a elas
        // Isto é uma estrutura que contem o seguinte
        // image1, image2, image3 -> nomes das imagens que vem do servidor
        // video -> verdadeiro ou falso. Se for verdadeiro é para usar video
        // videoLink -> link do video em caso de verdadeiro


        // VIDEO ID EXEMPLO: 76979871

        this.backCovers = this.$store.state.homepageModule.homepagedata.covers[0]
        
        console.log(this.backCovers)

        console.log(this.cover)



        
        // Como exemplo... Podes usar este código para o deploy final

        // Se a variável estiver definida, ou seja se for diferente de undefined
        if(this.backCovers != undefined){
            if(this.backCovers.image1 != '' && this.backCovers.image2 != '' && this.backCovers.image3 != ''){
                // Vamos esvaziar o array em cima, e deixamos as covers por defeito de fora
                let coversArray = []
                this.coversList = []

                if(this.backCovers.video == 0){
                    // Vamos carregar o array com as 3 covers com os seus nomes (image1, image2, image3)
                    coversArray.push({
                        text: "REAL ESTATE PARA INVESTIDORES PRIVADOS E EMPRESAS COM ELEVADOS PADRÕES DE EXIGÊNCIA",
                        img: 'https://api.la-agency.com/media/i/frontend/' + this.backCovers.image1,
                        imgMobile: 'https://api.la-agency.com/media/i/frontend/' + this.backCovers.image1,
                    })

                    // Vamos carregar o array com as 3 covers com os seus nomes (image1, image2, image3)
                    coversArray.push({
                        text: "REAL ESTATE PARA INVESTIDORES PRIVADOS E EMPRESAS COM ELEVADOS PADRÕES DE EXIGÊNCIA",
                        img: 'https://api.la-agency.com/media/i/frontend/' + this.backCovers.image2,
                        imgMobile: 'https://api.la-agency.com/media/i/frontend/' + this.backCovers.image2,
                    })

                    // Vamos carregar o array com as 3 covers com os seus nomes (image1, image2, image3)
                    coversArray.push({
                        text: "REAL ESTATE PARA INVESTIDORES PRIVADOS E EMPRESAS COM ELEVADOS PADRÕES DE EXIGÊNCIA",
                        img: 'https://api.la-agency.com/media/i/frontend/' + this.backCovers.image3,
                        imgMobile: 'https://api.la-agency.com/media/i/frontend/' + this.backCovers.image3,
                    })
                    

                    // Vamos popular novamente o array de covers
                    this.coversList = Object.keys(coversArray).map(key => {
                        return coversArray[key];
                    })

                    // Vamos começar na Cover na posição 0
                    this.cover = this.coversList[this.currentCover];
                }
            }
        }else{
            this.backCovers = {
                video: false
            }
        }

        /* {
         "text": "REAL ESTATE PARA INVESTIDORES PRIVADOS E EMPRESAS COM ELEVADOS PADRÕES DE EXIGÊNCIA",
         "img": "/img/cover/cover1.jpg",
         "imgMobile": "/img/cover/cover1_mobile.jpg"
       },
        {
         "text": "DESDE ASSESSORIA E CONSULTORIA, ATÉ À IDENTIFICAÇÃO DE OPORTUNIDADES DE INVESTIMENTO",
         "img": "/img/cover/cover2.jpg",
         "imgMobile": "/img/cover/cover2_mobile.jpg"
       },
        {
         "text": "PIONEIROS NA REDEFINIÇÃO DO REAL ESTATE DO FUTURO",
         "img": "/img/cover/cover3.jpg",
         "imgMobile": "/img/cover/cover3_mobile.jpg"
       }
       */
    },
    mounted() {
        this.interval = setInterval(this.toNextCover, this.intervalTime);
        document.getElementById('timer').classList.add("animate")
    },
    watch: {
        $route() {
            this.coversList = Object.keys(this.$i18n.t('homepage.cover')).map(key => {
                return this.$i18n.t('homepage.cover')[key];
            })
            this.cover = this.coversList[this.currentCover];
        },
    },
}
</script>

